<template>
  <div>
    <b-form class="mb-2" inline>

      <div class="w-25 p-1">
        <b-form-input @blur="search"
                      v-model="filter.text"
                      class="w-100"
                      :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('TRAVEL')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>

      <div class="w-25 p-1">
        <driver-single-selector :undefined-means="$t('SELECT_ONE_DRIVER')" @input="search" value-type="id" v-model="filter.driverId"></driver-single-selector>
      </div>
      <div class="w-25 p-1">
        <bus-single-selector :undefined-means="$t('SELECT_ONE_BUS')" @input="search" value-type="id" v-model="filter.busId"></bus-single-selector>
      </div>
      <div class="w-25 p-1">
        <turn-single-selector :undefined-means="$t('SELECT_ONE_TURN')" @input="search" value-type="id" v-model="filter.turnId"></turn-single-selector>
      </div>
      <div class="w-25 p-1">
        <company-single-selector :undefined-means="$t('SELECT_ONE_COMPANY')" @input="search" value-type="id" v-model="filter.companyId"></company-single-selector>
      </div>
      <div class="w-25 p-1">
        <route-single-selector :undefined-means="$t('SELECT_ONE_ROUTE')" @input="search" value-type="id" v-model="filter.routeId"></route-single-selector>
      </div>
      <div class="w-25 p-1">
        <travel-type-single-selector :undefined-means="$t('SELECT_ONE_TRAVEL_TYPE')" @input="search" value-type="id" v-model="filter.travelTypeId"></travel-type-single-selector>
      </div>
      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-25" id="status-select">
        <b-form-select-option :value="undefined">{{
            $t('FILTER_ALL_LABEL', {
              catalog: $t('TRAVEL'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="true">{{
            $t('FILTER_ACTIVE_LABEL', {
              catalog: $t('TRAVEL'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="false">{{
            $t('FILTER_INACTIVE_LABEL', {
              catalog: $t('TRAVEL'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
      </b-form-select>
    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter} from "@/mixins";
import DriverSingleSelector from "@/drivers/DriverSingleSelector";
import BusSingleSelector from "@/buses/BusSingleSelector";
import TurnSingleSelector from "@/turns/TurnSingleSelector";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import RouteSingleSelector from "@/routes/RouteSingleSelector";
import TravelTypeSingleSelector from "@/travel-types/TravelTypeSingleSelector";

export default {
  name: "TravelProgrammingListFilter",
  components: {
    TravelTypeSingleSelector,
    RouteSingleSelector, CompanySingleSelector, TurnSingleSelector, BusSingleSelector, DriverSingleSelector},
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if (this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>