<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'TRAVEL_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('TRAVEL')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- generate travels button -->
      <b-button v-if="debug" :disabled="loadingTravels" class="ml-1" @click="generateAllTravels" variant="dark">
        <b-icon-circle-fill animation="throb" v-if="loadingTravels"></b-icon-circle-fill>
        <b-icon-bezier v-else></b-icon-bezier>
        <span class="ml-1">{{ $t('TRAVELS_GENERATE_LABEL') | capitalize }}</span>
      </b-button>
      <!-- generate travels button -->

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->

      <!-- open travel close modal button -->
      <b-button :disabled="loadingTravels" class="ml-1" @click="openCloseTravelsModal" variant="dark">
        <b-icon-circle-fill animation="throb" v-if="closingTravels"></b-icon-circle-fill>
        <b-icon-lock v-else></b-icon-lock>
        <span class="ml-1">{{ $t('TRAVELS_CLOSE_LABEL') | capitalize }}</span>
      </b-button>
      <!-- open travel close modal button -->
    </div>
    <div>
      <!-- List page filter -->
      <travel-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                          v-model="filter"></travel-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="travel-table"
        ref="travelsTable"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        @row-clicked="showDetails"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :tbody-tr-class="rowClass"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('TRAVELS')})  | capitalize }}</strong>
        </div>
      </template>

      <template #row-details="row">
        <div v-if="loadingDetails[row.item.id]">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('TRAVELS')})  | capitalize }}</strong>
          </div>
        </div>
        <div v-else>
          <b-table-simple table-variant="dark" hover small responsive>
            <b-thead>
              <b-tr>
                <b-th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TRAVEL_DIRECTION') | capitalize }}</b-th>
                <b-th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TURN') | capitalize }}</b-th>
                <b-th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_COMPANY') | capitalize }}</b-th>
                <b-th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TRAVEL_TYPE') | capitalize }}</b-th>
                <b-th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_ROUTE') | capitalize }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="detail in details$[row.item.id].details" :key="detail.id">
                <b-td>
                  {{ detail.both ? $t('TRAVEL_PROGRAMMING_DETAIL_BOTH') : $t(detail.travelDirection) | capitalize }}
                </b-td>
                <b-td>{{ detail._embedded.turn.name }}</b-td>
                <b-td>{{ detail._embedded.company.name }}</b-td>
                <b-td>{{ detail._embedded.travelType.name }}</b-td>
                <b-td>{{ detail._embedded.route.name }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>

      <!-- travelId -->
      <template #head(travelId)="">
        <span>{{ labels.travelId | capitalize }}</span>
      </template>

      <template #cell(travelId)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.travelId }}</span>
        </div>
      </template>
      <!-- travelId -->

      <!-- company -->
      <template #head(company)="">
        <span>{{ labels.company | capitalize }}</span>
      </template>

      <template #cell(company)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.company }}</span>
        </div>
      </template>
      <!-- company -->

      <!-- number -->
      <template #head(number)="">
        <span>{{ labels.number | capitalize }}</span>
      </template>

      <template #cell(number)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.number }}</span>
        </div>
      </template>
      <!-- number -->

      <!-- turn -->
      <template #head(turn)="">
        <span>{{ labels.turn | capitalize }}</span>
      </template>

      <template #cell(turn)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.turn }}</span>
        </div>
      </template>
      <!-- turn -->

      <!-- route -->
      <template #head(route)="">
        <span>{{ labels.route | capitalize }}</span>
      </template>

      <template #cell(route)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.route }}</span>
        </div>
      </template>
      <!-- route -->

      <!-- driver -->
      <template #head(driver)="">
        <span>{{ labels.driver | capitalize }}</span>
      </template>

      <template #cell(driver)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.driver }}</span>
        </div>
      </template>
      <!-- driver -->

      <!-- driverType -->
      <template #head(driverType)="">
        <span>{{ labels.driverType | capitalize }}</span>
      </template>

      <template #cell(driverType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(getDriverType(+data.item.driverType)) | capitalize }}</span>
        </div>
      </template>
      <!-- driver -->

      <!-- bus -->
      <template #head(bus)="">
        <span>{{ labels.bus | capitalize }}</span>
      </template>

      <template #cell(bus)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.bus }}</span>
        </div>
      </template>
      <!-- bus -->

      <!-- date -->
      <template #head(date)="">
        <span>{{ labels.date | capitalize }}</span>
      </template>

      <template #cell(date)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.date | date }}</span>
        </div>
      </template>
      <!-- date -->

      <!-- travelType -->
      <template #head(travelType)="">
        <span>{{ labels.travelType | capitalize }}</span>
      </template>

      <template #cell(travelType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.travelType }}</span>
        </div>
      </template>
      <!-- travelType -->

      <!-- paymentType -->
      <template #head(paymentType)="">
        <span>{{ labels.paymentType | capitalize }}</span>
      </template>

      <template #cell(paymentType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.paymentType }}</span>
        </div>
      </template>
      <!-- paymentType -->

      <!-- from -->
      <template #head(from)="">
        <span>{{ labels.from | capitalize }}</span>
      </template>

      <template #cell(from)="data">
        <div style="text-align: left; vertical-align: center">
          {{ data.item.from | date }}
        </div>
      </template>
      <!-- from -->

      <!-- to -->
      <template #head(to)="">
        <span>{{ labels.to | capitalize }}</span>
      </template>

      <template #cell(to)="data">
        <div style="text-align: left; vertical-align: center">
          {{ data.item.to | date }}
        </div>
      </template>
      <!-- to -->

      <!-- finished -->
      <template #head(finished)="">
        <span>{{ labels.finished | capitalize }}</span>
      </template>

      <template #cell(finished)="data">
        <div style="text-align: center; vertical-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.finished"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>
      <!-- finished -->

      <!-- dieselPayroll -->
      <template #head(dieselPayroll)="">
        <span>{{ labels.dieselPayroll | capitalize }}</span>
      </template>

      <template #cell(dieselPayroll)="data">
        <div style="text-align: left; vertical-align: center">
          <div style="text-align: center">
            <b-icon-check-circle variant="success" v-if="data.item.dieselPayroll"></b-icon-check-circle>
            <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
          </div>
        </div>
      </template>
      <!-- dieselPayroll -->

      <!-- accountDayBefore -->
      <template #head(accountDayBefore)="">
        <span>{{ labels.accountDayBefore | capitalize }}</span>
      </template>

      <template #cell(accountDayBefore)="data">
        <div style="text-align: left; vertical-align: center">
          <div style="text-align: center">
            <b-icon-check-circle variant="success" v-if="data.item.accountDayBefore"></b-icon-check-circle>
            <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
          </div>
        </div>
      </template>
      <!-- accountDayBefore -->

      <!-- previousDate -->
      <template #head(previousDate)="">
        <span>{{ labels.previousDate | capitalize }}</span>
      </template>

      <template #cell(previousDate)="data">
        <div style="text-align: left; vertical-align: center">
          <span v-if="data.item.previousDate">{{ data.item.previousDate | date }}</span>
          <span v-else></span>
        </div>
      </template>
      <!-- previousDate -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_CREATE_TRAVELS_PERMISSION'"
                             @click="generateTravels(data.item.id)">
              {{ $t('TRAVELS_GENERATE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'TRAVEL_PROGRAMMING_DESTROY_TRAVELS_PERMISSION'"
                             @click="destroyTravels(data.item.id)">
              {{ $t('TRAVELS_DESTROY_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="travel-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>

    <b-modal id="close-travels-modal" :title="$t('CLOSE_TRAVELS_MODAL_TITLE')" @ok="closeTravels">
      <b-form>
        <p class="text-justify text-danger">{{ $t('CLOSE_TRAVELS_WARNING') }}</p>
        <b-form-group
          :label="$t('CLOSE_TRAVEL_DATE')">
          <b-datepicker v-model="closeDate"></b-datepicker>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {DateFilter, Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import Travels from "@/travel-programmings";
import eventBus from "@/events";
import TravelListFilter from "@/travel-programmings/TravelProgrammingListFilter";
import PageSize from "@/components/PageSize";
import TravelProgramming from "@/travel-programmings";
import moment from "moment";

export default {
  name: "TravelProgrammingList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, TravelListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, DateFilter],
  mounted() {
    eventBus.$on('LIST_COUNT_TRAVEL_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    openCloseTravelsModal() {
      this.$bvModal.show('close-travels-modal');
    },
    async closeTravels() {
      this.closingTravels = true;
      await TravelProgramming.closeTravelsTo(this.closeDate);
      this.closingTravels = false;
      this.sendMessage('TRAVELS_CLOSED_OK', {});
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (moment(item.to).toDate() < new Date()) return 'table-danger'
    },
    getDriverType(type) {
      return type === 0 ? 'DRIVER_TYPE_EMPLOYEE' : 'DRIVER_TYPE_ASSOCIATED'
    },
    generateAllTravels: async function () {
      try {
        this.isBusy = true;
        const resp = await this.controller.generateAllTravels();
        console.log(resp.data);
        this.sendMessage('TRAVELS_GENERATED_OK', {travels: resp.data.length});
      } catch (e) {
        console.error(e);
        this.sendError('TRAVELS_GENERATED_ERROR', {})
      } finally {
        this.isBusy = false;
      }
    },
    generateTravels: async function (id) {
      try {
        this.isBusy = true;
        const resp = await this.controller.generateTravels(id);
        console.log(resp.data);
        this.sendMessage('TRAVELS_GENERATED_OK', {travels: resp.data.length});
      } catch (e) {
        console.error(e);
        this.sendError('TRAVELS_GENERATED_ERROR', {})
      } finally {
        this.isBusy = false;
      }
    },
    async destroyTravels(id) {
      try {
        if (confirm(this.$t('CONFIRM_DELETE_TRAVELS_MESSAGE'))) {
          this.isBusy = true;
          await this.controller.destroyTravels(id);
          this.sendMessage('TRAVELS_DESTROYED_OK');
        }
      } catch (e) {
        console.error(e);
        this.sendError('TRAVELS_DESTROYED_ERROR', {})
      } finally {
        this.isBusy = false;
      }
    },
    async showDetails(row) {
      try {
        row._showDetails = !row._showDetails;
        if (row._showDetails) {
          this.loadingDetails$[row.id] = true;
          const resp = await TravelProgramming.findById(row.id);
          this.details$[row.id] = resp.data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDetails[row.id] = false;
        this.$forceUpdate();
        console.log(this.loadingDetails$[row.id])
      }
    },
  },
  data() {
    return {
      labels: {
        number: this.$t('TRAVEL_NUMBER_LABEL'),
        driver: this.$t('TRAVEL_DRIVER_LABEL'),
        driverType: this.$t('TRAVEL_DRIVER_TYPE_LABEL'),
        bus: this.$t('TRAVEL_BUS_LABEL'),
        paymentType: this.$t('TRAVEL_PAYMENT_TYPE_LABEL'),
        from: this.$t('TRAVEL_FROM_LABEL'),
        to: this.$t('TRAVEL_TO_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('TRAVELS'),
        excel: this.$t('TRAVELS') + '.xls',
      },
      closeDate: new Date(),
      closingTravels: false,
      loadingDetails$: {},
      details$: {},
      loadingTravels: false,
      registerPage: 'TRAVEL_PROGRAMMING_REGISTER_TITLE',
      editPage: 'TRAVEL_PROGRAMMING_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return Travels;
    },
    loadingDetails() {
      return this.loadingDetails$;
    },
    fields() {
      return [
        {key: 'number', sortable: true, tdClass: 'customTd'},
        {key: 'driver', sortable: true, tdClass: 'customTd'},
        {key: 'driverType', sortable: true, tdClass: 'customTd'},
        {key: 'bus', sortable: true, tdClass: 'customTd'},
        {key: 'paymentType', sortable: true, tdClass: 'customTd'},
        {key: 'from', sortable: true, tdClass: 'customTd'},
        {key: 'to', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.travelsTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.driver] = 'driver';
      fields[this.labels.driverType] = 'driverType';
      fields[this.labels.bus] = 'bus';
      fields[this.labels.paymentType] = 'paymentType';
      fields[this.labels.from] = 'from';
      fields[this.labels.to] = 'to';
      fields[this.labels.enabled] = 'enabled';
      return fields;
    },
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
